@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

body {
  @apply !bg-[#F2F6FA];
}
